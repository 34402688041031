import React from 'react';
import Header from './Header';
import MateriaSlider from './MateriaSlider';
import SideBySideItems from './SideBySideItems';
import SimpleParagraph from './SimpleParagraph';
import Footer from './Footer';
import firstMemberProfile from '../images/contenu/marcotte.eric.profil.png';
import secondMemberProfile from '../images/contenu/moreau.ralph.profil.png';
import thirdMemberProfile from '../images/contenu/gutierrez.philippe.profil.png';
import Hrline from './Hrline';
import { Container, Grid } from '@material-ui/core';
import { withNamespaces } from 'react-i18next';
import "materialize-css/dist/css/materialize.min.css";
import "materialize-css/dist/js/materialize.min.js";
import { styles } from "./styles";

class Network extends React.Component {

  constructor(props) {
    super(props);
    this.networkRef = React.createRef(); 
  }

    render(){
      const { t } = this.props;
      const hrcolor = "#d9d9d9";
        return (
            <Container style={styles.noMarginPadding}>
              <Header/>
              <MateriaSlider slideThreeBigTitle={t('slideThreeBigTitle', { returnObjects: true })}
                    slideThreeSmallTitle={t('slideThreeSmallTitle', { returnObjects: true })}
                    learnMore={t('learnMore', { returnObjects: true })} slider={3}
                    reference={this.networkRef}/>
              <SimpleParagraph title={t('teamTitle', { returnObjects: true })} 
              contentText={null} lineLength={8} reference={this.networkRef}/>
              <Grid container item sm={12} 
                    style={{...styles.marginTop50,...styles.marginBottom50,...{height: "auto"}}}>
                <SideBySideItems title={t('firstTeamMemberRole', { returnObjects: true })}
                  contentText={t('firstTeamMember', { returnObjects: true })}
                  image={firstMemberProfile} imageAlt="firstMemberProfil"
                  widthImg={556*.5} heightImg={669*.5} imageAndText={true}/>
              </Grid>
              <Hrline hrlength={12} lineThickness={1} hrcolor={hrcolor}/>
              <Grid container item sm={12} 
                    style={{...styles.marginTop50,...styles.marginBottom50,...{height: "auto"}}}>
                <SideBySideItems title={t('secondTeamMemberRole', { returnObjects: true })}
                  contentText={t('secondTeamMember', { returnObjects: true })}
                  image={secondMemberProfile} imageAlt="secondMemberProfil"
                  widthImg={556*.5} heightImg={669*.5} imageAndText={true}/>
              </Grid>
              <Hrline hrlength={12} lineThickness={1} hrcolor={hrcolor}/>
              <Grid container item sm={12} 
                    style={{...styles.marginTop50,...styles.marginBottom50,...{height: "auto"}}}>
                <SideBySideItems title={t('thirdTeamMemberRole', { returnObjects: true })}
                  contentText={t('thirdTeamMember', { returnObjects: true })}
                  image={thirdMemberProfile} imageAlt="secondMemberProfil"
                  widthImg={556*.5} heightImg={669*.5} imageAndText={true}/>
              </Grid>
              <Footer/>
            </Container>
        );
    }
}

export default withNamespaces()(Network);