import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Homepage from './Homepage';
import Services from './Services';
import Network from './Network';
import Contacts from './Contacts';
import Projects from './Projects';
import { withNamespaces } from 'react-i18next';
import routesData from '../public/locales/routes/routes.json';
import PageError from './PageError';
import UnderConstruction from './UnderConstruction';
import {LanguageContext, languages} from './LanguageContext';

class App extends React.Component {

// Same Site Cookies: https://www.youtube.com/watch?v=GPz7onXjP_4

  constructor(props) {
    super(props);
    this.state = {
      language: localStorage.getItem('i18nextLng')
    };

    this.toggleLanguage = () => {
      const { i18n } = this.props;
      if(this.state.language === languages.french){
        i18n.changeLanguage(languages.english);
        this.setState({language: languages.english});
     } else {
        i18n.changeLanguage(languages.french);
        this.setState({language: languages.french});
     }
    };
  }

  render(){
    const showConstruction = false
    return(
      showConstruction ? <UnderConstruction/> : 
        <LanguageContext.Provider value={{language : this.state.language, toggleLanguage: this.toggleLanguage}}>
          <BrowserRouter>
            <div>
              <Switch>
                <Route path={routesData[0].url} component={Homepage} exact/>
                <Route path={routesData[1].url} component={Services} exact/>
                <Route path={routesData[2].url} component={Network} exact/>
                <Route path={routesData[3].url} component={Projects} exact/>
                <Route path={routesData[4].url} component={Contacts} exact/>
                <Route component={PageError} exact/>
              </Switch>
            </div>
          </BrowserRouter>
        </LanguageContext.Provider>
    );
  }
}

export default withNamespaces()(App);