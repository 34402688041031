import React from 'react';
import { Grid } from '@material-ui/core';

class Hrline extends React.Component {
    
    render(){

        const hrlineStyle = {
            paddingBottom: this.props.lineThickness,
            boxShadow: `inset 0px ${this.props.lineThickness}px 0px 0px ${this.props.hrcolor}`
        }

        return(
            <Grid container>
                <Grid item xs/>
                <Grid item xs={this.props.hrlength}>
                    <div style={hrlineStyle}/>
                </Grid>
                <Grid item xs/>
            </Grid>
        );
    }
}

export default Hrline;