import React from 'react';
import Header from './Header';
import Footer from './Footer';
import SimpleParagraph from './SimpleParagraph';
import SideBySideItems from './SideBySideItems';
import MateriaSlider from './MateriaSlider';
import { Container, Grid } from '@material-ui/core';
import { withNamespaces } from 'react-i18next';
import Hrline from './Hrline';
import "materialize-css/dist/css/materialize.min.css";
import "materialize-css/dist/js/materialize.min.js";
import product from '../images/contenu/product.jpg';
import fpt from '../images/contenu/FPT.png';
import { styles } from "./styles";

class Projects extends React.Component {

  constructor(props) {
    super(props);
    this.projectRef = React.createRef(); 
  }

    render(){
      const { t } = this.props;
      const hrcolor = "#d9d9d9";
      const infoLinksFair="https://fair.stratemia.com";
      const infoLinksRPA="https://akabot.com/";

        return (
          <Container style={styles.noMarginPadding}>
          <Header/>
          <MateriaSlider 
                slideFourSmallTitle={t('slideFourSmallTitle', { returnObjects: true })}
                slideFourBigTitle={t('slideFourBigTitle', { returnObjects: true })}
                learnMore={t('learnMore', { returnObjects: true })} slider={4}
                reference={this.projectRef}/>
          <SimpleParagraph title={t('projectTitle', { returnObjects: true })} 
              contentText={t('projectContent', { returnObjects: true })} lineLength={1}
              reference={this.projectRef}/>
          <Hrline hrlength={12} lineThickness={1} hrcolor={hrcolor}/>
          <Grid container item sm={12} 
            style={{...styles.marginTop50,...styles.marginBottom50,...{height: "auto"}}}>
            <SideBySideItems title={t('projectTitleOne', { returnObjects: true })}
                          contentText={t('projectContentOne', { returnObjects: true })}
                          image={product} imageAlt="bigData" infoText = {t('projectInfoText', { returnObjects: true })} infoLinks={infoLinksFair}
                          widthImg={"100%"} heightImg={"100%"} imageAndText={false}/>
          </Grid>
          <Hrline hrlength={12} lineThickness={1} hrcolor={hrcolor}/>
          <Grid container item sm={12} 
            style={{...styles.marginTop50,...styles.marginBottom50,...{height: "auto"}}}>
            <SideBySideItems title={t('projectTitleTwo', { returnObjects: true })}
                          contentText={t('projectContentTwo', { returnObjects: true })}
                          image={fpt} imageAlt="fpt" infoText = {t('projectInfoText', { returnObjects: true })} infoLinks={infoLinksRPA}
                          widthImg={"100%"} heightImg={"100%"} imageAndText={true}/>
          </Grid>
          <Hrline hrlength={12} lineThickness={1} hrcolor={hrcolor}/>
          <Footer/>
        </Container>
        );
    }
}

export default withNamespaces()(Projects);