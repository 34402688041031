import React from 'react';
// import axios from 'axios';
import { Grid, Button} from '@material-ui/core';
import { styles } from "./styles";
import { mediaQuery } from "./mediaQuery";

class ContactForm extends React.Component { 

  constructor(props){
    super(props);

    this.state = {
      subject : "",
      email: "",
      message: "",
      windowWidth: document.body.clientWidth
    }

    this.changeSubject = this.changeSubject.bind(this);
    this.changeEmail = this.changeEmail.bind(this);
    this.changeMessage = this.changeMessage.bind(this);

  }

  changeSubject(event) {
    this.setState({subject: event.target.value});
  }

  changeEmail(event) {
    this.setState({email: event.target.value});
  }

  changeMessage(event) {
    this.setState({message: event.target.value});
  }

  componentDidMount() {
    window.addEventListener('resize', () => {
    this.setState({windowWidth: document.body.clientWidth})
    });
  }

  componentDidUpdate(){
    let alertMessage = document.querySelector('#alert-message');
    alertMessage.innerHTML = "";
  }

  handleSubmit = async(e) => {
    e.preventDefault();
    let alertMessage = document.querySelector('#alert-message');
    let currentComponent = this;
    
    await fetch("/api/v1",{
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        subject: this.state.subject,
        email: this.state.email,
        message: this.state.message
      })
    })
    .then(response => {
      // console.log(response);
      currentComponent.setState({subject: "", email: "", message: ""});
      let successMessage = currentComponent.props.successEmailAlert;
      alertMessage.innerHTML = JSON.parse(JSON.stringify(successMessage));
    })
    .catch(error =>  {
      // console.log(error);
      let errorMessage = currentComponent.props.errorEmailAlert;
      alertMessage.innerHTML = JSON.parse(JSON.stringify(errorMessage));
    });
  };

  render () {

    const contactFormStyle = {
      backgroundColor: `${styles.lightBlueColor}`,
      padding: 30
    }

    const alertFontStyle = {
      color: "#023f64",
      marginTop: 20,
      fontWeight: "bold",
      fontSize: 18
    }

    var cyber =require('../images/contenu/cyber.jpg');

    return (
      <Grid container 
        style={{ backgroundImage : "url(" + cyber +")",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                width: "100%",
                height: "100%",
                paddingTop:50, paddingBottom: 50}}>
        <Grid item xs/>
        <Grid item xs={this.state.windowWidth < mediaQuery.mediumDesktop ? 10 : 5}>
            <h4 style={{...styles.naNumGothicTextFont, ...styles.title, ...{textAlign: "center", color: "white"}}}>Contacts</h4>
            <form onSubmit={this.handleSubmit} style={contactFormStyle}>
              <input type="text" value={this.state.subject} onChange={this.changeSubject} style={{...styles.inputSyle,...{height: 50}}}
                placeholder="Subject" className="outlined-email-input" required></input>
              <input type="text" value={this.state.email} onChange={this.changeEmail} style={{...styles.inputSyle,...{height: 50}}}
                placeholder="Email" className="outlined-email-input" required></input>
              <textarea type="textarea" value={this.state.message} onChange={this.changeMessage} style={{...styles.inputSyle,...{height: 100, paddingTop:10}}}
              maxLength="140" rows="28" className="outlined-email-input" placeholder="Message" required></textarea>
              <div id="alert-message" style={alertFontStyle}></div>
              <Button style={{...styles.signup, ...{ marginTop: 30}}} variant="contained" type="submit">Submit</Button>
            </form>
        </Grid>
        <Grid item xs/>
      </Grid>
    );
  }
}

export default ContactForm;