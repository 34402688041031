
const noMarginPadding =  {
    margin: "0 auto",
    maxWidth: "100%",
    padding: "0"
}

const naNumGothicTextFont = {
    fontFamily: "'Nanum Gothic', sans-serif"
}

const marginTop50 = {
    marginTop: 50
}

const marginTop25 = {
    marginTop: 25
}

const marginTop100 ={
    marginTop: 100
}

const marginBottom50 ={
    marginBottom: 50
}

const verticalAlign = {
    verticalAlign: "middle",
    alignContent: "center",
    alignItems: "center"
}

const textColor = {
    color: "#04222f"
}

const textLogoDescription = {
    width: 50,
    height: 50
}

const outlineLeft = {
    boxShadow: "inset 1px 0px 0px 0px white"
}

const turquoiseButton = {
    backgroundColor: '#008099', 
    color: 'white'
}

const title = {
    texTtransform: "uppercase", 
    fontSize: 30,
    fontWeight: "bold"
}


const ParagraphContent = {
    lineHeight: "1.6", 
    textAlign: "justify"
}

const marginTop0 = {
    marginTop: 0
}

const CompanyLogo = {
    width: 264,
    height: 75
}

const sliderHeight = {
    height: 500
}

const imageSlider = {
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "50% 50%",
    maxWidth: "100% !important",
    height: "550px !important",
    opacity:"0.9"
}

const carouselButton = {
    borderRadius: "0",
    backgroundColor: "white",
    color: "#008099",
    width: 200,
    height: 45,
    margin: 0
}

const lightBlueColor = "#ecf2f9"

const topHeader = {
    background: "linear-gradient(90deg, rgba(9,9,121,1) 0%, rgba(2,0,36,1) 0%, rgba(0,154,185,1) 100%)",
    color: "white",
    height: 50,
    opacity: 0.9,
    naNumGothicTextFont      
}

const footerTop = {
    backgroundColor: lightBlueColor,
    color: "white"
}

const footerBottom = {
    color: "white",
    background: "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,87,121,1) 100%, rgba(0,212,255,1) 100%)"
}

const inputSyle = {
    backgroundColor: "white",
    paddingLeft: 10, 
    marginTop: 30, 
    boxSizing: "border-box"
} 

const signup = {
    marginTop: 0,
    backgroundColor: "#008099", 
    color: "white",
    borderRadius: 0,
    height: 47
}

const directionLeft = {
    direction: "rtl",
    textAlign:"justify"
}

const socialMedia = {
    marginLeft: 4,
    marginRight: 4,
    fontSize: 30
}

const headerHeight = {
    height: 120
}

export const styles = {
    noMarginPadding: noMarginPadding,
    naNumGothicTextFont: naNumGothicTextFont,
    marginTop100: marginTop100,
    marginTop50: marginTop50,
    marginTop25: marginTop25,
    marginTop0: marginTop0,
    marginBottom50: marginBottom50,
    verticalAlign: verticalAlign,
    title: title,
    ParagraphContent: ParagraphContent,
    textColor: textColor,
    textLogoDescription: textLogoDescription,
    turquoiseButton: turquoiseButton,
    CompanyLogo: CompanyLogo,
    sliderHeight: sliderHeight,
    imageSlider: imageSlider,
    carouselButton: carouselButton,
    footerTop: footerTop,
    footerBottom: footerBottom,
    headerHeight: headerHeight,
    signup: signup,
    topHeader: topHeader,
    directionLeft: directionLeft,
    socialMedia: socialMedia,
    lightBlueColor: lightBlueColor,
    inputSyle : inputSyle,
    outlineLeft: outlineLeft
}