export const mediaQuery = {
    desktop: 1200,
    mediumDesktop: 992,
    tablet: 768,
    phone: 576
}

export const setPropertyValue = (windowWidth,value1,value2,value3) => {
    if(windowWidth > mediaQuery.desktop){
        return value1
    } else if(windowWidth > mediaQuery.mediumDesktop){
        return value2
    }  
    return value3
}