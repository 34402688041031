import React from 'react';
import Header from './Header';
import Footer from './Footer';
import SimpleParagraph from './SimpleParagraph';
import SideBySideItems from './SideBySideItems';
import MateriaSlider from './MateriaSlider';
import { Container, Grid } from '@material-ui/core';
import { withNamespaces } from 'react-i18next';
import Hrline from './Hrline';
import ai from '../images/contenu/Artificial_intelligence.png';
import analytics from '../images/contenu/Advanced_analytics.png';
import model from '../images/contenu/Modeling.jpg';
import recruitment from '../images/contenu/Recrutement.png';
import automation from '../images/contenu/Automation.jpg';
import strategy from '../images/contenu/Strategic_advice.jpg';
import "materialize-css/dist/css/materialize.min.css";
import "materialize-css/dist/js/materialize.min.js";
import { styles } from "./styles";

class Services extends React.Component {

  constructor(props) {
    super(props);
    this.servicesRef = React.createRef(); 
  }

    render(){
      const { t } = this.props;
      const hrcolor = "#d9d9d9";
        return (
          <Container style={styles.noMarginPadding}>
          <Header/>
          <MateriaSlider slideTwoBigTitle={t('slideTwoBigTitle', { returnObjects: true })}
                slideTwoSmallTitle={t('slideTwoSmallTitle', { returnObjects: true })}
                learnMore={t('learnMore', { returnObjects: true })} slider={2}
                reference={this.servicesRef}/>
          <SimpleParagraph title={t('servicesTitle', { returnObjects: true })} 
                contentText={t('servicesContent', { returnObjects: true })} lineLength={1} reference={this.servicesRef}/>
          <Hrline hrlength={12} lineThickness={1} hrcolor={hrcolor}/>
          <Grid container item sm={12} 
            style={{...styles.marginTop50,...styles.marginBottom50,...{height: "auto"}}}>
            <SideBySideItems title={t('firstServiceTitle', { returnObjects: true })} 
                          contentText={t('firstServiceContent', { returnObjects: true })}
                          image={ai} imageAlt="bigData"
                          widthImg={"100%"} heightImg={"100%"} imageAndText={false}/>
          </Grid>
          <Hrline hrlength={12} lineThickness={1} hrcolor={hrcolor}/>
          <Grid container item sm={12} 
            style={{...styles.marginTop50,...styles.marginBottom50,...{height: "auto"}}}>
            <SideBySideItems title={t('secondServiceTitle', { returnObjects: true })}
              contentText={t('secondServiceContent', { returnObjects: true })}
              image={analytics} imageAlt="bigData"
              widthImg={"100%"} heightImg={"100%"} imageAndText={true}/>
          </Grid>
          <Hrline hrlength={12} lineThickness={1} hrcolor={hrcolor}/>
          <Grid container item sm={12} 
            style={{...styles.marginTop50,...styles.marginBottom50,...{height: "auto"}}}>
            <SideBySideItems title={t('thirdServiceTitle', { returnObjects: true })} 
                          contentText={t('thirdServiceContent', { returnObjects: true })}
                          image={model} imageAlt="bigData"
                          widthImg={"100%"} heightImg={"100%"} imageAndText={false}/>
          </Grid>
          <Hrline hrlength={12} lineThickness={1} hrcolor={hrcolor}/>
          <Grid container item sm={12} 
            style={{...styles.marginTop50,...styles.marginBottom50,...{height: "auto"}}}>
            <SideBySideItems title={t('fourthServiceTitle', { returnObjects: true })} 
                          contentText={t('fourthServiceContent', { returnObjects: true })}
                          image={recruitment} imageAlt="bigData"
                          widthImg={"100%"} heightImg={"100%"} imageAndText={true}/>
          </Grid>
          <Hrline hrlength={12} lineThickness={1} hrcolor={hrcolor}/>
          <Grid container item sm={12} 
            style={{...styles.marginTop50,...styles.marginBottom50,...{height: "auto"}}}>
            <SideBySideItems title={t('fifthServiceTitle', { returnObjects: true })} 
                          contentText={t('fifthServiceContent', { returnObjects: true })}
                          image={automation} imageAlt="bigData"
                          widthImg={"100%"} heightImg={"100%"} imageAndText={false}/>
          </Grid>
          <Hrline hrlength={12} lineThickness={1} hrcolor={hrcolor}/>
          <Grid container item sm={12} 
            style={{...styles.marginTop50,...styles.marginBottom50,...{height: "auto"}}}>
            <SideBySideItems title={t('sixthServiceTitle', { returnObjects: true })} 
                          contentText={t('sixthServiceContent', { returnObjects: true })}
                          image={strategy} imageAlt="bigData"
                          widthImg={"100%"} heightImg={"100%"} imageAndText={true}/>
          </Grid>
          <Footer/>
        </Container>
        );
    }
}

export default withNamespaces()(Services);