import React from 'react';
// import { Grid, Button } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import { styles } from "./styles";
import { mediaQuery } from "./mediaQuery";
// import { withNamespaces } from 'react-i18next';

class Footer extends React.Component {
    constructor(props){
        super(props);
        this.state = {
          windowWidth: document.body.clientWidth
        }
    }

    componentDidMount() {
        window.addEventListener('resize', () => {
        this.setState({windowWidth: document.body.clientWidth})
        });
    }



    render(){
        const styleSocialMediaDesktop = {
            ...styles.verticalAlign, 
            ...styles.directionLeft 
        }

        const styleSocialMediaMobile = {
            ...styles.verticalAlign,
            width: "100%",
            paddingLeft: "33%"
        }

        return( 
        <span>
            <Grid container item sm={12}  align="center" 
            style={{...styles.marginTop100, ...styles.footerTop, ...styles.verticalAlign}}>
                <div style={{ display: "block", width: "100%", margin: '0 auto', height: 75}}>
                </div>
            </Grid>
            <Grid container item sm={12} style={{...styles.footerBottom}}>
                <Grid container item sm={12} style={{height: 100, marginTop: 10}}>
                    {this.state.windowWidth < mediaQuery.tablet ? null : <Grid container item xs={1}/>}
                    <Grid container item xs={this.state.windowWidth < mediaQuery.tablet ? 12 : 5}  style={{...styles.verticalAlign,...styles.naNumGothicTextFont}}>
                    {this.state.windowWidth < mediaQuery.tablet ? 
                    <div style={{ textAlign: "center", width: "100%"}}>Copyright Ⓒ2020 Stratemia CANADA Inc.</div>:
                    <div>Copyright Ⓒ2020 Stratemia CANADA Inc.</div>}
                    </Grid>
                    <Grid container item xs={this.state.windowWidth < mediaQuery.tablet ? 12 : 5}
                        style={this.state.windowWidth < mediaQuery.tablet ? styleSocialMediaMobile : styleSocialMediaDesktop}>
                        <span style={{ textTransform: 'uppercase'}}>Suivez-nous</span>
                        <span style={{ marginLeft: 15}}/>
                        <a href="https://www.linkedin.com/company/stratemia" style={{ color: "white"}}><LinkedInIcon style={{...styles.socialMedia}}/></a>
                        <a href="https://twitter.com/_cire_eric_" style={{ color: "white"}}><TwitterIcon  variant="white" style={{...styles.socialMedia}}/></a>
                    </Grid>
                    {this.state.windowWidth < mediaQuery.tablet ? null : <Grid container item xs={1}/>}
                </Grid>
            </Grid>
        </span>
        );
    }
}

export default Footer;