import React from 'react';
import construction from '../images/contenu/under_construction.png';

class UnderConstruction extends React.Component {
  render(){

    const stylesConstructionLogo = {
      width: 80,
      height: 80
    }

    const stylesConstruction = {
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      display: "table"    
    }

    return(
      <div style={stylesConstruction}>
      {/* Source : https://www.cleanpng.com/png-computer-icons-architectural-engineering-building-2228791/preview.html*/}
        <div style={{ display: "table-cell", verticalAlign: "middle"}}><img alt="construction" src={construction} style={stylesConstructionLogo}/></div>
        <div style={{display: "table-cell", fontSize: 45, 
                verticalAlign: "middle", textAlign: "center",
                width: 525}}>Site under Construction</div>
        <div style={{ display: "table-cell", verticalAlign: "middle"}}><img alt="construction" src={construction} style={stylesConstructionLogo}/></div>
    </div>
    );
  }
}

export default UnderConstruction;