import React from 'react';
import logo from '../images/logo/logo.png';
//import logo from '../../images/logo/logo_old.png';
import { Grid, Button } from '@material-ui/core';
import { Navbar, Nav, NavLink } from 'reactstrap';
import { withNamespaces } from 'react-i18next';
import routesData from '../public/locales/routes/routes.json';
import { styles } from "./styles";
import { setPropertyValue } from "./mediaQuery";
import PhoneIcon from '@material-ui/icons/Phone';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import { LanguageContext, languages } from './LanguageContext';


class Header extends React.Component {
    constructor(props){
        super(props);
        this.state = {
          phone: "514.250.0945",
          email: "info@stratemia.com",
          companyName: "Stratemia",
          windowWidth: document.body.clientWidth,
          isOpen: false,
          isHoverMobileOption: false,
          textdecorationEmail: "none"
        }

        this.openCloseMenu = this.openCloseMenu.bind(this);
      }


    openCloseMenu(){
        this.setState({isOpen: !this.state.isOpen});
    }

    componentDidMount() {
        window.addEventListener('resize', () => {
        this.setState({windowWidth: document.body.clientWidth})
        });
    }

    render () {

    const { t } = this.props;

    const transparentButton = {
        backgroundColor: "Transparent",
        backgroundRepeat:"no-repeat",
        border: "none",
        cursor: "pointer",
        overflow: "hidden",
        outline: "none"
    }

    const mobileMenu = {

        // background: "#d9e6f2",
        background: "#e6e6e6",
        borderTop: "1px solid white",
        lineHeight: 2,
        color: "#264d73",
        height: 45
    }

    const infoEmail = {
        textDecoration : this.state.textdecorationEmail,
        color: "white"
    } 

        return (
            <LanguageContext.Consumer>{({language, toggleLanguage}) => (
                <span>
                    <Grid container style={styles.topHeader}>
                        {setPropertyValue(this.state.windowWidth,1,2,3) !== 1 ? <Grid container item xs style={{ height: 'inherit'}}/> : <Grid container item xs={7} style={{ height: 'inherit'}}/>}
                        <Grid container item xs={setPropertyValue(this.state.windowWidth,5,9,9)} style={{ height: 'inherit', color: "white"}}>
                            <Grid container item xs={setPropertyValue(this.state.windowWidth,6,5,5)} justify="center" style={{...styles.outlineLeft,...styles.verticalAlign}}><PhoneIcon/>{this.state.phone}</Grid>
                            <Grid container item xs={setPropertyValue(this.state.windowWidth,6,7,7)} justify="center" style={{...styles.outlineLeft,...styles.verticalAlign}}><MailOutlineIcon/>
                                <NavLink href={routesData[4].url} style={infoEmail} 
                                onMouseEnter={() => this.setState({ textdecorationEmail: "underline" })} 
                                onMouseLeave={() => this.setState({ textdecorationEmail: "none" })}  >{this.state.email}</NavLink>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container item sm={12} style={{...styles.headerHeight,...styles.naNumGothicTextFont}}>
                        <Grid container item sm={setPropertyValue(this.state.windowWidth,5,4,12)} 
                            style={{...styles.verticalAlign,
                            ...styles.headerHeight}}>
                                <div style={{ display: "table", width: "100%",padding: 0}}>
                                    <div style={{ display: "table-cell", padding: 0}}><img src={logo} style={{...styles.CompanyLogo,...{marginLeft:setPropertyValue(this.state.windowWidth,"35%","20%","5%")}}} alt="logo"/></div>
                                    <div style={{...styles.verticalAlign,...{ display: "table-cell", padding: 0}}}>
                                        <span style={{marginRight: "20%", padding: 0, display: setPropertyValue(this.state.windowWidth,"none","none","block")}}>
                                            <button style={transparentButton} onClick={() => this.setState({ isOpen: !this.state.isOpen })}>
                                                { this.state.isOpen ? <CloseIcon style={{ fontSize : 35 }}/> : <MenuIcon style={{fontSize : 35, color: "#002b33"}}/>}
                                            </button>
                                        </span>
                                    </div>
                                </div> 
                        </Grid>
                        <Grid container item sm={setPropertyValue(this.state.windowWidth,7,8,null)} align="center" 
                            style={{ height: 'inherit', display: setPropertyValue(this.state.windowWidth,"flex","flex","none"), 
                                fontWeight: setPropertyValue(this.state.windowWidth,15,13,13)}}>
                            <Grid container item sm={12} style={{ height: 'inherit', display: "block"}}>
                                <Navbar align="right" style={{ height: 'inherit', paddingLeft: 25, 
                                    paddingRight: setPropertyValue(this.state.windowWidth,"13%","5%","5%")}}>
                                    <Nav  style={{ height: 'inherit'}}>
                                    {t('menu', { returnObjects: true }).map((dynamicMenu,i) =>
                                        <Menu  key={i} url={routesData[i].url} menuHeight={120} menu={dynamicMenu}></Menu>)}
                                        <li style={{ display: "table", width: "auto"}}>
                                            <div style={{ display: "table-cell", verticalAlign: "middle"}}>
                                                <Button variant="contained" style={styles.turquoiseButton} 
                                                onClick={toggleLanguage}>
                                                    {language === languages.french ? languages.english : languages.french}
                                                </Button> 
                                            </div>
                                        </li>
                                    </Nav>
                                </Navbar>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container item sm={12} style={{ height: 270, padding:0, display : this.state.isOpen && this.state.windowWidth < 992 ? "block" : "none"}}>
                        <ul id="mobileMenu" style={{...styles.naNumGothicTextFont,...{ textAlign: "center", fontSize: 20}}}>
                            {t('menu', { returnObjects: true }).map((dynamicMenu,i) => 
                            <li key={i} style={mobileMenu}>
                                <a key={i} href={routesData[i].url} style={{display: "block", height: "inherit"}}>{dynamicMenu.text}</a>
                            </li>)}
                            <li style={mobileMenu}>
                                <NavLink style={{display: "block", cursor: "pointer", height: "inherit"}}
                                onClick={toggleLanguage}>
                                    {language === languages.french ? languages.english : languages.french}
                                </NavLink>
                            </li>
                        </ul>
                    </Grid>
                </span>
            )}</LanguageContext.Consumer>
        );
    }
}

class Menu extends React.Component {

    render(){
        return(
        <li style={{ height: this.props.menuHeight}}><NavLink style={{marginRight: 35, height: this.props.menuHeight}} href={this.props.url}>{this.props.menu.text}</NavLink></li>
        );
    }
}

export default withNamespaces()(Header);