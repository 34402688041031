import React from 'react';
// import './Homepage.css';
import processor from '../images/contenu/processor.png'
import freesoftware from '../images/contenu/copyleft.png'
import magnifyingglass from '../images/contenu/magnifying_glass.png'
import Header from './Header';
import Footer from './Footer';
import MateriaSlider from './MateriaSlider';
import { Container, Grid } from '@material-ui/core';
import { withNamespaces } from 'react-i18next';
import "materialize-css/dist/css/materialize.min.css";
import "materialize-css/dist/js/materialize.min.js";
import { styles } from "./styles";
import { mediaQuery } from "./mediaQuery";


class Homepage extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      windowWidth: document.body.clientWidth,
      HomePageRef: React.createRef()
    }
  }

  componentDidMount(){
      window.addEventListener('resize', () => {
      this.setState({windowWidth: document.body.clientWidth})
      });
  }

  render () {
    const { t } = this.props;
    
      return (
        <Container style={styles.noMarginPadding}>
          <Header/>
          <MateriaSlider slideOneBigTitle={t('slideOneBigTitle', { returnObjects: true })}
              slideOneSmallTitle={t('slideOneSmallTitle', { returnObjects: true })}
              learnMore={t('learnMore', { returnObjects: true })} slider={1}
              reference={this.state.HomePageRef}/>
          <Grid container item sm={12} 
            style={{...styles.naNumGothicTextFont,  
                    ...styles.marginTop50}}>
            <Grid container item sm={1}/>
            <Grid container item sm={10} style={styles.textColor}>
              <Grid container item sm={this.state.windowWidth < mediaQuery.desktop ? 12 : 4} 
                style={{paddingLeft: 20, paddingRight: 20}}>
                <div ref={this.state.HomePageRef}>
                  <img alt="processor" src={processor} style={styles.textLogoDescription}/>
                  <p style={styles.title}>{t('agnosticTitle', { returnObjects: true })}</p>
                  <p style={styles.ParagraphContent}>{t('agnosticContent', { returnObjects: true })}</p>
                </div>
              </Grid>
              <Grid container item sm={this.state.windowWidth < mediaQuery.desktop ? 12 : 4} 
                style={{paddingLeft: 20, paddingRight: 20, marginTop: this.state.windowWidth < mediaQuery.desktop ? 50 : 0}}>
                <div>
                  <img alt="freesoftware" src={freesoftware} style={styles.textLogoDescription}/>
                  <p style={styles.title}>{t('freesoftTitle', { returnObjects: true })}</p>
                  <p style={styles.ParagraphContent}>{t('freesoftContent', { returnObjects: true })}</p>
                </div>
              </Grid>
              <Grid container item sm={this.state.windowWidth < mediaQuery.desktop ? 12 : 4} 
                style={{paddingLeft: 20, paddingRight: 20, marginTop: this.state.windowWidth < mediaQuery.desktop ? 50 : 0}}>
                <div>
                <img alt="magnifyingglass" src={magnifyingglass} style={styles.textLogoDescription}/>
                  <p style={styles.title}>{t('ethicsTitle', { returnObjects: true })}</p>
                  <p style={styles.ParagraphContent}>{t('ethicsContent', { returnObjects: true })}</p>
                </div>
              </Grid>
            </Grid>
            <Grid container item sm={1}/>
          </Grid>
          <Grid container item sm={12} style={styles.marginTop100}>
            <Grid container item sm={1}/>
              <Grid container item sm={10} style={{paddingLeft: 20, paddingRight: 20}}>
                <Grid container item sm={6} style={styles.naNumGothicTextFont}>
                  <div>
                    <p style={{...styles.title,...styles.marginTop0}}>{t('missionTitle', { returnObjects: true })}</p>
                  </div>
                </Grid>
                <Grid container item sm={6} >
                  <div>
                    <p style={{...styles.ParagraphContent,...styles.marginTop0}}>{t('missionContent', { returnObjects: true })}</p>
                  </div>
                </Grid>
              </Grid>
            <Grid container item sm={1}/>
          </Grid>
          <Footer/>
        </Container>
      );
    }
}

export default withNamespaces()(Homepage);