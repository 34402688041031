import React from 'react';

export const languages = {
    french : "fr",
    english : "en"
};

export const LanguageContext = React.createContext({
    language : languages.french,
    toggleLanguage: () => {},
});