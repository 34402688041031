import React from 'react';
import Header from './Header';
import Footer from './Footer';
import ContactForm from './ContactForm';
import { Container, Grid,  } from '@material-ui/core';
import { withNamespaces } from 'react-i18next';
import "materialize-css/dist/css/materialize.min.css";
// import "materialize-css/dist/js/materialize.min.js";
import { styles } from "./styles";

class Contacts extends React.Component {

  render () {
    const { t } = this.props;
    
      return (
        <Container style={styles.noMarginPadding}>
          <Header/>
          <Grid container item sm={12} style={{height: "auto"}}>
              <Grid item xs/>
              <Grid container item xs={5} style={{textAlign: "center"}}>
               
                  {/*t('profile', { returnObjects: true })*/}
                
              </Grid>
              <Grid item xs/>
          </Grid>
          <Grid container item sm={12} style={{ marginTop: 20}}>
            <ContactForm errorEmailAlert={t('alertErrorEmail', { returnObjects: true })}
                          successEmailAlert={t('alertSuccessEmail', { returnObjects: true })}
            />
          </Grid>
          {/*<Grid container item sm={12} style={{height: "auto", marginTop: 100}}>
            <div style={{marginLeft: "auto", marginRight: "auto", fontSize: 25}}>Under Construction</div>
        </Grid>*/}
          <Footer/>
        </Container>
      );
    }
}


export default withNamespaces()(Contacts);
