import React from 'react';
import { Grid } from '@material-ui/core';
import { styles } from "./styles";
import Hrline from './Hrline';
class SimpleParagraph extends React.Component {

    render(){
        const lightbluecolor = "#2eb8b8"
        return(
            <Grid container style={{...styles.naNumGothicTextFont, ...styles.marginTop25, ...styles.marginBottom50}}>
                <Grid container item xs/>
                <Grid container item xs={8} style={styles.textColor}>
                    <div style={{ textAlign: "center", margin: "auto"}} ref={this.props.reference}>
                        {/*<p style={{color: lightbluecolor, letterSpacing: 2, margin: 10}}><b>{this.props.title}</b></p>*/}
                        { this.props.title != null ? <p style={{...styles.title,...{ margin: 10}}}>{this.props.title}</p> : null }
                        <Hrline hrlength={this.props.lineLength} lineThickness={3} hrcolor={lightbluecolor}/>
                        {this.props.contentText != null ? <p style={{...styles.ParagraphContent,...{ margin: 10}}}>{this.props.contentText}</p> : null }
                    </div>
                </Grid>
                <Grid container item xs/>
            </Grid>
        );
    }

}

export default SimpleParagraph;