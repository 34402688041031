import React from 'react';
// import routesData from '../public/locales/routes/routes.json';
import homeBanner from '../images/carousel/banner_home.jpg';
import servicesBanner from '../images/carousel/banner_services.jpg';
import networkBanner from '../images/carousel/banner_network.jpg';
import projectsBanner from '../images/carousel/banner_projects.jpg';
import { Slider, Slide, Caption } from "react-materialize";
import { Grid, Button } from '@material-ui/core';
import { styles } from "./styles";
// import { NavLink } from 'reactstrap';

class MateriaSlider extends React.Component {
    
    render(){

        const executeScroll = () => {
            setTimeout(() => { window.scrollTo(0, this.props.reference.current.offsetTop) }, 500);
        }

        const slideOne = 
        <Slide image={<img alt="business" src={homeBanner} style={styles.imageSlider}/>}>
            <Caption placement="left">
                <h4>
                    {this.props.slideOneBigTitle}
                </h4>
                <h5 className="light grey-text text-lighten-3" style={{ marginBottom : '4%'}}>
                    {this.props.slideOneSmallTitle}
                </h5>
                <Button variant="contained" style={styles.carouselButton}
                    onClick={executeScroll}>{this.props.learnMore}</Button>
            </Caption>
        </Slide>

        const slideTwo = 
        <Slide image={<img alt="office" src={servicesBanner} style={styles.imageSlider}/>}>
            <Caption placement="right">
                <h4>
                    {this.props.slideTwoBigTitle}
                </h4>
                <h5 className="light grey-text text-lighten-3" style={{ marginBottom : '4%'}}>
                    {this.props.slideTwoSmallTitle}
                </h5>
                <Button variant="contained" style={styles.carouselButton}
                    onClick={executeScroll}>{this.props.learnMore}</Button>
            </Caption>
        </Slide>

        const slideThree =
        <Slide image={<img alt="network" src={networkBanner} style={styles.imageSlider}/>}>
            <Caption placement="center">
                <h4>
                    {this.props.slideThreeBigTitle}
                </h4>
                <h5 className="light grey-text text-lighten-3" style={{ marginBottom: '4%'}}>
                    {this.props.slideThreeSmallTitle}
                </h5>
                <Button variant="contained" style={styles.carouselButton}
                    onClick={executeScroll}>{this.props.learnMore}</Button>
            </Caption>
        </Slide>

        const slideFour =
        <Slide image={<img alt="science" src={projectsBanner} style={styles.imageSlider}/>}>
            <Caption placement="right">
                <h4>
                    {this.props.slideFourBigTitle}
                </h4>
                <h5 className="light grey-text text-lighten-3" style={{ marginBottom: '4%'}}>
                    {this.props.slideFourSmallTitle}
                </h5>
                <Button variant="contained" style={styles.carouselButton}
                    onClick={executeScroll}>{this.props.learnMore}</Button>
            </Caption>
        </Slide>
        return(
            <Grid container item sm={12} 
                style={{...styles.marginBottom, ...styles.noMarginPadding, ...styles.sliderHeight}}>
                <Slider fullscreen={false}
                    options={{ duration: 500, indicators: false, height: 475, interval: 100000}}>
                        {this.props.slider === 1 ? slideOne : null }
                        {this.props.slider === 2 ? slideTwo : null }
                        {this.props.slider === 3 ? slideThree : null }
                        {this.props.slider === 4 ? slideFour : null }
                </Slider>
            </Grid>
        );
    }
}

export default MateriaSlider;