import React from 'react';
import { Grid } from '@material-ui/core';
import "materialize-css/dist/css/materialize.min.css";
import "materialize-css/dist/js/materialize.min.js";
import { styles } from "./styles";

class SideBySideItems extends React.Component {

    constructor(props){
        super(props);
        this.state = {
          windowWidth: document.body.clientWidth,
        }
    }

    componentDidMount(){
        window.addEventListener('resize', () => {
        this.setState({windowWidth: document.body.clientWidth})
        });
    }

    render(){
        const imageItem = 
        <Grid container item sm={this.state.windowWidth < 1200 ? 12 : 5} 
            style={{...styles.naNumGothicTextFont, 
            ...{padding: 0, 
                paddingLeft: this.state.windowWidth > 1200 && this.props.imageAndText === false ? 50 : 0,
                paddingRight: this.state.windowWidth > 1200 && this.props.imageAndText === true ? 50 : 0  
                }}}>
            <div style={{ display: "block", marginRight: "auto", marginLeft: "auto"}}>
                <img alt={this.props.imageAlt} style={{width: this.props.widthImg, height: this.props.heightImg}} src={this.props.image}/>
            </div>
        </Grid>

        const textitem =
        <Grid container item sm={7} style={{ display : this.state.windowWidth < 1200 ? "none" : "block"}}>
            <div style={{ marginRight: "auto", marginLeft: "auto"}}>
                {this.props.title != null ? <p style={{...styles.ParagraphTitle, ...styles.marginTop0}}><b style={{ fontSize: 20}}>{this.props.title}</b></p> : null }
                {this.props.contentText != null ? <p style={{...styles.ParagraphContent, ...styles.marginTop0}}>{this.props.contentText}</p> : null }
                {this.props.infoText !== null ? <a href={this.props.infoLinks}>{this.props.infoText}</a> : null}

            </div>
        </Grid>

        return (
            <span style={{ width: "100%"}}>
                <Grid container item sm={12} style={{ height: "auto"}}>
                    <Grid container item xs/>
                    <Grid container item xs={this.state.windowWidth < 1200 ? 12 : 8} spacing={5}
                        style={{...styles.verticalAlign,...{display:"flex", padding: 0}}}>
                            {this.props.imageAndText === true ? imageItem : textitem}
                            {this.props.imageAndText === true ? textitem : imageItem}
                    </Grid>
                    <Grid container item xs/>
                </Grid>
                <Grid container item sm={12} style={{ display : this.state.windowWidth < 1200 ? "block" : "none", marginTop: 25}}>
                    <div style={{ display: "table", margin: "auto", paddingLeft: "5%", paddingRight: "5%"}}>
                        <div style={{ display: "table-cell"}}>
                        {this.props.title != null ? <p style={styles.marginTop25}><b style={{ fontSize: 20}}>{this.props.title}</b></p> : null }
                        {this.props.contentText != null ? <p style={{...styles.ParagraphContent, ...styles.marginTop0}}>{this.props.contentText}</p> : null }
                        {this.props.infoText !== null ? <a href={this.props.infoLinks}>{this.props.infoText}</a> : null}
                        </div>
                    </div>
                </Grid>
            </span>
            
        );
    }
}

export default SideBySideItems;