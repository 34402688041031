import React from 'react';
import Header from './Header';
import Footer from './Footer';
import { Container } from '@material-ui/core';
import { withNamespaces } from 'react-i18next';
import "materialize-css/dist/css/materialize.min.css";
import "materialize-css/dist/js/materialize.min.js";
import { styles } from "./styles";

class PageError extends React.Component {

    render(){
        return (
            <Container style={styles.noMarginPadding}>
              <Header/>
              <div style={{ textAlign: "center"}}>
                  <h4> Page Error</h4>
              </div>
              <Footer/>
            </Container>
        );
    }
}

export default withNamespaces()(PageError);